import React, { useState } from 'react';

const AnswerInput = ({ numCards, handleSubmit, correctAnswers, disabled }) => {
  const [answers, setAnswers] = useState(Array(numCards).fill(''));
  const [feedback, setFeedback] = useState(Array(numCards).fill(''));

  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const newFeedback = answers.map((answer, index) => (
      parseInt(answer) === correctAnswers[index] ? 'correct' : 'incorrect'
    ));
    setFeedback(newFeedback);
    handleSubmit(answers);
  };

  return (
    <form className='FlashCardsForm' onSubmit={onSubmit}>
      {answers.map((answer, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <input
            type="text"
            value={answer}
            onChange={(e) => handleChange(index, e.target.value)}
            placeholder={`Answer ${index + 1}`}
            disabled={disabled || feedback[index] === 'correct'}
            style={{
              backgroundColor: feedback[index] === 'correct' ? 'green' : feedback[index] === 'incorrect' ? 'red' : 'white',
              color: feedback[index] ? 'white' : 'black',
            }}
          />
      
        </div>
      ))}
      {!disabled && <button className='practice-btn red-bg' type="submit">Check</button>}
    </form>
  );
};

export default AnswerInput;
